import React, { forwardRef } from "react";
import { Helmet } from "react-helmet-async";

import PropTypes from "prop-types";
import Header from "components/Header";
import Drawer from "components/Drawer";
import { makeStyles } from "@mui/styles";
import GlobalContext from "context/GlobalContext";

const useStyles = makeStyles((theme) => ({
  page: {
    backgroundSize: "cover",
  },
}));

const Page = forwardRef(
  ({ children, title = "", header = false, ...rest }, ref) => {
    const classes = useStyles();
    const { isLogin } = React.useContext(GlobalContext);

    return (
      <div
        ref={ref}
        {...rest}
        className={classes.page}
        style={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          paddingTop: 50,
          paddingBottom: 50,
          backgroundColor: "#F6F7F9",
        }}
      >
        <Helmet>
          <title>
            {title} | {process.env.REACT_APP_PROJECT_NAME}
          </title>
        </Helmet>
        {isLogin && <Header />}
        <Drawer />

        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Page;
