import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

function StatusForm(props) {
  const [rentId, setRentId] = useState(props.rentId ?? "");
  const [status, setStatus] = useState(props.status ?? "missing");

  const { enqueueSnackbar } = useSnackbar();

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleRentIdChange = (e) => {
    setRentId(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const { data } = await axios.put(
        "https://api.banto.io/banto2/app/rent/status",
        {
          rentId,
          status,
        }
      );

      enqueueSnackbar("반납 상태가 변경됐습니다", { variant: "info" });

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Stack>
          <FormControl fullWidth>
            <FormLabel id="change-status">기기 상태 변경</FormLabel>
            <TextField
              id="outlined-basic"
              label="렌트 아이디"
              variant="outlined"
              margin="normal"
              value={rentId}
              onChange={handleRentIdChange}
            />
            <RadioGroup
              row
              aria-labelledby="change-status"
              name="row-radio-buttons-group"
              defaultValue={status}
              onChange={handleStatusChange}
            >
              <FormControlLabel value="rent" control={<Radio />} label="빌림" />
              <FormControlLabel
                value="missing"
                control={<Radio />}
                label="도망감"
              />
              <FormControlLabel
                value="return"
                control={<Radio />}
                label="찾음"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            상태 변경
          </Button>
        </Stack>
      </Container>
    </div>
  );
}

export default StatusForm;
