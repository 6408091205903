import { Container, Button, Tooltip } from "@mui/material";
import moment from "moment";
import axios from "axios";
import DoneIcon from "@mui/icons-material/Done";
import DataTable from "components/DataTable";
import Page from "components/Page";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

const ApprovalButton = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const { userId, storeName, stationIds } = params.data;

  const handleApproval = async () => {
    try {
      const { data: approvalResult } = await axios.post(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/approval`,
        {
          userId,
          storeName,
          stationIds,
        }
      );

      enqueueSnackbar("승인 완료", { variant: "info" });
    } catch (error) {
      enqueueSnackbar("승인 실패", { variant: "error" });
    }
  };

  return (
    <>
      <Tooltip title="승인">
        <Button
          onClick={async () => {
            if (window.confirm("가맹점 등록을 승인합니다")) {
              await handleApproval();
            }
          }}
        >
          <DoneIcon />
        </Button>
      </Tooltip>
    </>
  );
};

function Index() {
  const [rows, setRows] = useState([]);

  const columns = [
    { field: "userId", headerName: "파트너스 ID", width: 150 },
    {
      field: "storeName",
      headerName: "가맹점명",
      width: 150,
    },
    {
      field: "phoneNumber",
      headerName: "휴대폰번호",
      width: 200,
    },
    { field: "address", headerName: "주소", width: 400 },
    {
      field: "businessHourWeekdaysStart",
      headerName: "평일 오픈시간",
      width: 150,
    },
    {
      field: "businessHourWeekdaysEnd",
      headerName: "평일 마감시간",
      width: 150,
    },
    {
      field: "businessHourWeekendsStart",
      headerName: "주말 오픈시간",
      width: 150,
    },
    {
      field: "businessHourWeekendsEnd",
      headerName: "주말 마감시간",
      width: 150,
    },
    {
      field: "closeDays",
      headerName: "휴무일",
      width: 150,
    },
    {
      field: "stationIds",
      headerName: "스테이션",
      width: 200,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        if (params.value) {
          return params.value.join("\n");
        }
      },
    },
    {
      field: "createdAt._seconds",
      headerName: "등록일",
      width: 130,
      filter: false,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        console.log(params);
        return `${moment(params.value * 1000).format("YYYY-MM-DD")}\n${moment(
          params.value * 1000
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "",
      filter: false,
      width: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center ",
      },
      cellRenderer: ApprovalButton,
    },
  ];

  useEffect(() => {
    (async () => {
      const { data: partners } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/store/pre`
      );

      setRows(partners.data);
    })();
  }, []);

  return (
    <Page title={"파트너스 가맹점 등록"}>
      <Container maxWidth="xl">
        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>
    </Page>
  );
}

export default Index;
