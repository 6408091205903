import React, { useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { errorFactory } from "utils";
import GlobalContext from "context/GlobalContext";
import { useHistory } from "react-router";
import { Login } from "Auth";

const GuestForm = ({ children, params }) => {
  const { setIsLogin, setUser, setDialogMessage } = useContext(GlobalContext);
  const { t } = useTranslation();
  const history = useHistory();

  const { email, password } = params;

  const onSubmit = async (e) => {
    e.preventDefault();

    switch (params.page) {
      case "login": {
        try {
          const { user } = await Login(email, password);
          setUser(user.uid);
          setIsLogin(true);
          history.replace("/");
        } catch (error) {
          console.log(error);
          return setDialogMessage(t("이메일과 비밀번호를 다시 확인해주세요"));
        }

        break;
      }

      case "register": {
        if (!params.isConfirm) {
          return setDialogMessage(t("이메일 인증이 완료되지 않았습니다."));
        }
        if (params.password !== params.passwordCheck) {
          return setDialogMessage(t("두 비밀번호가 맞지 않습니다."));
        }
        if (params.password.length < 6) {
          return setDialogMessage(t("비밀번호가 짧습니다."));
        }
        if (params.wPassword !== params.wPasswordCheck) {
          return setDialogMessage(t("두 출금 비밀번호가 맞지 않습니다."));
        }
        if (params.wPassword.length < 6) {
          return setDialogMessage(t("출금 비밀번호가 짧습니다."));
        }

        const body = {
          email: params.email.trim(),
          password: params.password.trim(),
          wPassword: params.wPassword.trim(),
          recommend: params.recommend.trim(),
          token: params.token.trim(),
          id: params.isConfirm,
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST}/register`,
          body
        );
        if (data.status) {
          setDialogMessage(
            t("회원가입이 완료되었습니다. 입력하신 정보로 로그인해주세요.")
          );
          history.push("/");
        } else {
          return setDialogMessage(errorFactory(data.error));
        }

        break;
      }

      case "forgot": {
        if (!params.isConfirm) {
          return setDialogMessage(t("이메일 인증이 완료되지 않았습니다."));
        }
        if (params.password !== params.passwordCheck) {
          return setDialogMessage(t("두 비밀번호가 맞지 않습니다."));
        }
        if (params.password.length < 6) {
          return setDialogMessage(t("비밀번호가 짧습니다."));
        }

        const body = {
          email: params.email.trim(),
          password: params.password.trim(),
          token: params.token.trim(),
          id: params.isConfirm,
        };

        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST}/forgot`,
          body
        );
        if (data.status) {
          setDialogMessage(
            t("비밀번호가 변경되었습니다. 입력하신 정보로 로그인해주세요.")
          );
          history.push("/");
        } else {
          return setDialogMessage(errorFactory(data.error));
        }

        break;
      }
      default:
        break;
    }
  };

  return React.cloneElement(children, {
    onSubmit,
  });
};

export default GuestForm;
