import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";

function DateSearch({
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
  searchClick,
}) {
  return (
    <Stack direction="row" spacing={1}>
      <DatePicker
        dateFormat="yyyy.MM.dd (eee)"
        selected={startDate}
        locale={ko}
        showPopperArrow={false}
        maxDate={new Date()}
        onChange={changeStartDate}
        customInput={<TextField label="대여일" />}
      />
      <Typography fontSize={35} fontWeight={200}>
        -
      </Typography>
      <DatePicker
        dateFormat="yyyy.MM.dd (eee)"
        selected={endDate}
        locale={ko}
        showPopperArrow={false}
        maxDate={new Date()}
        onChange={changeEndDate}
        customInput={<TextField label="대여일" />}
      />
      <Button variant="contained" onClick={searchClick}>
        조회
      </Button>
    </Stack>
  );
}

export default DateSearch;
