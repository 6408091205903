import React from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";

function FormInput({ name, ...otherProps }) {
  const configTextFiled = {
    ...otherProps,
    fullWidth: true,
  };

  return (
    <Field name={name}>
      {({
        field,
        form: { handleChange, handleBlur },
        meta: { touched, error },
      }) => {
        return (
          <TextField
            {...configTextFiled}
            onChange={handleChange}
            onKeyUp={handleBlur}
            error={touched && !!error}
            helperText={touched && !!error ? error : ""}
          />
        );
      }}
    </Field>
  );
}

export default FormInput;
