import React, { useEffect, useState } from "react";

import axios from "axios";

import DataTable from "components/DataTable";
import Page from "components/Page";

import { Button, Chip, Container, Tooltip } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DataObjectIcon from "@mui/icons-material/DataObject";
import DeleteIcon from "@mui/icons-material/Delete";
import kakaopayIcon from "assets/img/kakaopay_icon.png";

import moment from "moment";
import { useSnackbar } from "notistack";

const cellStyle = {
  height: "100%",
  display: "flex",
  alignItems: "center",
};

function Index() {
  const [rows, setRows] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getList = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/log/payment-fail`
    );

    setRows(data.list);
  };

  useEffect(() => {
    getList();
  }, []);

  const statusCellRenderer = (params) => {
    const paymentType =
      params.data.type === "kakaopay" ? (
        <img src={kakaopayIcon} alt="" width="30%" />
      ) : (
        <CreditCardIcon />
      );

    let label = "";
    let color = "";

    if (params.data.returnType === "return") {
      label = "반납";
      color = "success";
    } else {
      label = "대여";
      color = "primary";
    }

    return (
      <>
        <Chip
          icon={paymentType}
          label={label}
          size="small"
          color={color}
          sx={{ width: "130px" }}
        />
      </>
    );
  };

  const responseCellRenderer = (params) => {
    const apiData = params.data.kakaoResponse ?? params.data.apiResponse;
    const response = Object.entries(apiData).map((x) => {
      return JSON.stringify(x);
    });

    return (
      <Tooltip title={response}>
        <DataObjectIcon />
      </Tooltip>
    );
  };

  const deleteCellRenderer = (params) => {
    const deleteLog = async () => {
      if (window.confirm("로그를 삭제합니다")) {
        const { data: deleteResult } = await axios.delete(
          `${process.env.REACT_APP_API_URL}/banto2/app/log/payment-fail/${params.data.type}/${params.data.rentId}`
        );

        enqueueSnackbar(deleteResult.msg);

        await getList();
      }
    };

    return (
      <Button onClick={deleteLog}>
        <DeleteIcon />
      </Button>
    );
  };

  const columns = [
    {
      field: "returnType",
      headerName: "결제 시도",
      width: 130,
      cellStyle,
      cellRenderer: statusCellRenderer,
    },
    {
      field: "createdAt",
      headerName: "결제 실패일",
      width: 140,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
          params.value
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "userId",
      headerName: "회원 ID",
      cellStyle,
      width: 150,
    },
    {
      field: "rentId",
      headerName: "대여 ID",
      cellStyle,
      width: 150,
    },
    {
      field: "reason",
      headerName: "사유",
      cellStyle,
      width: 300,
    },
    {
      field: "price",
      filter: false,
      headerName: "금액",
      cellStyle,
      width: 100,
    },

    {
      field: "",
      filter: false,
      headerName: "API 응답 데이터",
      width: 150,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: responseCellRenderer,
    },
    {
      field: "",
      filter: false,
      width: 100,
      cellStyle,
      cellRenderer: deleteCellRenderer,
    },
  ];

  return (
    <Page title={"출금 실패"}>
      <Container maxWidth="xl">
        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>
    </Page>
  );
}

export default Index;
