import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Button,
  TextField,
  Stack,
  Dialog,
  Autocomplete,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@mui/styles";

import Page from "components/Page";

import DaumPostcode from "react-daum-postcode";

import GlobalContext from "context/GlobalContext";
import axios from "axios";
import { useSnackbar } from "notistack";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  countHelperText: {
    "&.MuiFormHelperText-root": {
      textAlign: "right",
      color: "blue",
    },
  },
}));

const banks = [
  { value: "경남은행", label: "경남은행" },
  { value: "광주은행", label: "광주은행" },
  { value: "국민은행", label: "국민은행" },
  { value: "기업은행", label: "기업은행" },
  { value: "농협", label: "농협" },
  { value: "지역농협", label: "지역농협" },
  { value: "대구은행", label: "대구은행" },
  { value: "부산은행", label: "부산은행" },
  { value: "도이치은행", label: "도이치은행" },
  { value: "산업은행", label: "산업은행" },
  { value: "상호저축은행", label: "상호저축은행" },
  { value: "새마을금고", label: "새마을금고" },
  { value: "수협", label: "수협" },
  { value: "신한은행", label: "신한은행" },
  { value: "신협", label: "신협" },
  { value: "씨티은행", label: "씨티은행" },
  { value: "외한은행", label: "외한은행" },
  { value: "우리은행", label: "우리은행" },
  { value: "우체국", label: "우체국" },
  { value: "전북은행", label: "전북은행" },
  { value: "제주은행", label: "제주은행" },
  { value: "카카오뱅크", label: "카카오뱅크" },
  { value: "하나은행", label: "하나은행" },
  { value: "케이뱅크", label: "케이뱅크" },
  { value: "SC은행", label: "SC은행" },
  { value: "HSBC은행", label: "HSBC은행" },
  { value: "교보증권", label: "교보증권" },
  { value: "대신증권", label: "대신증권" },
  { value: "대우증권", label: "대우증권" },
  { value: "동부증권", label: "동부증권" },
  { value: "메리츠종합금융증권", label: "메리츠종합금융증권" },
  { value: "미래에셋증권", label: "미래에셋증권" },
  { value: "부국증권", label: "부국증권" },
  { value: "삼성증권", label: "삼성증권" },
  { value: "신영증권", label: "신영증권" },
  { value: "신한금융투자", label: "신한금융투자" },
  { value: "아이엠투자증권", label: "아이엠투자증권" },
  { value: "우리투자증권", label: "우리투자증권" },
  { value: "유안타증권", label: "유안타증권" },
  { value: "유진투자증권", label: "유진투자증권" },
  { value: "이트레이드증권", label: "이트레이드증권" },
  { value: "키움증권", label: "키움증권" },
  { value: "하나대투증권", label: "하나대투증권" },
  { value: "하이투자증권", label: "하이투자증권" },
  { value: "한국투자증권", label: "한국투자증권" },
  { value: "한화투자증권", label: "한화투자증권" },
  { value: "현대증권", label: "현대증권" },
  { value: "HMC투자증권", label: "HMC투자증권" },
  { value: "LIG투자증권", label: "LIG투자증권" },
  { value: "NH농협증권", label: "NH농협증권" },
  { value: "SK증권", label: "SK증권" },
  { value: "산림조합", label: "산림조합" },
];

const Index = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();

  const [id, setId] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licenseImage, setLicenseImage] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [uploadButtonLoading, setUploadButtonLoading] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);

  const [addressSearchOpen, setAddressSearchOpen] = useState(false);

  const handleAddressSearch = (data) => {
    let fullAddress = data.address;
    let detailAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        detailAddress += data.bname;
      }
      if (data.buildingName !== "") {
        detailAddress +=
          detailAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += detailAddress !== "" ? ` (${detailAddress})` : "";
    }

    setAddress(fullAddress);
    setAddressSearchOpen(false);
  };

  const handleModify = async () => {
    try {
      const { data: modifyResult } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/${user}`,
        {
          licenseNumber,
          bank,
          account,
          address,
          detailAddress,
        }
      );

      if (modifyResult.data) {
        enqueueSnackbar("변경 완료", { variant: "info" });
      }
    } catch (error) {
      enqueueSnackbar("변경 실패", { variant: "error" });
    }
  };

  const getInfo = async () => {
    setInfoLoading(false);

    const { data: userData } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/partners/${user}`
    );

    setId(userData.data.id);
    setIsBusiness(userData.data.isBusiness);
    setLicenseNumber(userData.data.license.number);
    setEmail(userData.data.email);
    setName(userData.data.name);
    setPhoneNumber(userData.data.phoneNumber);
    setBank(userData.data.bank.name);
    setAccount(userData.data.bank.account);
    setAddress(userData.data.address.default);
    setDetailAddress(userData.data.address.detail);
    setLicenseImage(userData.data.license.image);

    setInfoLoading(true);
  };

  const handleUpload = async (file) => {
    try {
      setUploadButtonLoading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `license/${id}`);

      const metadata = {
        contentType: file.type,
      };

      const uploadTask = await uploadBytes(storageRef, file, metadata);

      const downloadUrl = await getDownloadURL(uploadTask.ref);

      const { data: modifyResult } = await axios.patch(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/${user}`,
        {
          licenseImage: downloadUrl,
        }
      );

      if (modifyResult.data) {
        enqueueSnackbar("업로드 완료", { variant: "info" });
        getInfo();
      }
    } catch (error) {
      enqueueSnackbar("업로드 실패", { variant: "error" });
      setUploadButtonLoading(false);
    }
  };

  useEffect(() => {
    if (selectedImage) {
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    getInfo();
  }, []);

  return infoLoading ? (
    <Page title={t("내정보")}>
      <Container maxWidth="xs" className={classes.root}>
        <Stack spacing={2}>
          <TextField
            id="id"
            label="추천 코드"
            value={id}
            inputProps={{ readOnly: true }}
            helperText="영업한 가맹점에 알려주시면 됩니다"
          />

          <TextField
            id="email"
            label="아이디(이메일)"
            value={email}
            inputProps={{ readOnly: true }}
          />

          <TextField
            id="name"
            label="이름"
            value={name}
            inputProps={{ readOnly: true }}
          />

          <TextField
            label="휴대폰 번호"
            value={phoneNumber}
            inputProps={{ inputMode: "numeric", maxLength: 11, readOnly: true }}
          />

          <TextField
            label="사업자 여부"
            value={isBusiness ? "사업자" : "개인"}
            inputProps={{ readOnly: true }}
          />

          <TextField
            label={isBusiness ? "사업자등록번호" : "주민등록번호"}
            value={licenseNumber}
            onChange={(e) => {
              setLicenseNumber(e.target.value);
            }}
            inputProps={{ maxLength: 15 }}
          />

          {!licenseImage && previewUrl && selectedImage && (
            <Box mt={2} textAlign="center">
              <img src={previewUrl} alt={selectedImage.name} height="200" />
            </Box>
          )}

          {isBusiness && !licenseImage ? (
            <Box>
              <input
                label="사업자등록증 업로드"
                accept="image/*"
                type="file"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
              <LoadingButton
                loading={uploadButtonLoading}
                variant="contained"
                size="small"
                onClick={() => {
                  handleUpload(selectedImage);
                }}
              >
                사업자등록증 업로드
              </LoadingButton>
            </Box>
          ) : (
            isBusiness && (
              <Box mt={2} textAlign="center">
                <img src={licenseImage} alt="사업자등록증" height="200" />
              </Box>
            )
          )}

          <Stack spacing={1}>
            <Autocomplete
              fullWidth
              disablePortal
              options={banks}
              defaultValue={{ value: bank, label: bank }}
              onChange={(e, newValue) => {
                setBank(newValue.value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField {...params} label="은행" />}
            />
            <TextField
              fullWidth
              label="계좌번호"
              inputProps={{ inputMode: "numeric" }}
              value={account}
              onChange={(e) => {
                setAccount(e.target.value);
              }}
              helperText="정산 받으실 계좌를 입력해주세요"
            />
          </Stack>

          <Stack spacing={1}>
            <TextField
              fullWidth
              label="주소"
              value={address}
              onClick={() => {
                setAddressSearchOpen(true);
              }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              label="상세 주소"
              value={detailAddress}
              onChange={(e) => {
                setDetailAddress(e.target.value);
              }}
              helperText="스테이션을 받으실 주소를 입력해주세요"
            />
          </Stack>

          <Button variant="contained" onClick={handleModify}>
            정보 수정
          </Button>
        </Stack>

        <Dialog
          open={addressSearchOpen}
          onClose={() => {
            setAddressSearchOpen(false);
          }}
        >
          <DaumPostcode onComplete={handleAddressSearch} />
        </Dialog>
      </Container>
    </Page>
  ) : (
    ""
  );
};

export default Index;
