import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Button,
  IconButton,
  Box,
  Typography,
  Stack,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoneyIcon from "assets/img/money_icon.png";
import CrownIcon from "assets/img/crown_icon.png";
import CoinIcon from "assets/img/coin_icon.png";
import KakaoIcon from "assets/img/kakao_icon.png";

import NumberFormat from "react-number-format";
import axios from "axios";
import moment from "moment";

function Index({ history }) {
  const now = new Date();
  const [profit, setProfit] = useState([]);
  const [rents, setRents] = useState([]);
  const [rentsLimit, setRentsLimit] = useState(5);

  const startDate = moment(new Date(now.setDate(now.getDate() - 1))).format(
    "YYYY-MM-DD"
  );
  const endDate = moment().format("YYYY-MM-DD");

  const nameMasking = (strName) => {
    if (strName === undefined || strName === "") {
      return "";
    }

    const reg = new RegExp("(?<=.{1}).", "gi");
    return strName.replace(reg, "*");
  };

  const getRents = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/rent?startDate=${startDate}&endDate=${endDate}`
      );

      setRents(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfit = async () => {
    const { data } = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/banto2/app/partners/revenue/sales?startDate=${moment()
        .subtract(1, "month")
        .format("YYYY-MM-01")}&endDate=${moment()
        .subtract(1, "month")
        .format("YYYY-MM-31")}`
    );

    const profitArray = data.data;
    profitArray.sort((a, b) => {
      if (a.totalPrice < b.totalPrice) {
        return 1;
      }
      if (a.totalPrice > b.totalPrice) {
        return -1;
      }
      return 0;
    });

    setProfit(profitArray);
  };

  useEffect(() => {
    getProfit();
    getRents();
  }, []);

  return (
    <Container sx={{ backgroundColor: "#F6F7F9", padding: "30px 20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ margin: "30px 0px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>
              <Typography fontSize={24} fontWeight={700}>
                Banto
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{ color: "#000000" }}
                onClick={() => history.push("/login")}
              >
                로그인
              </Button>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Box>
              <Typography fontSize={12} fontWeight={400}>
                지속적인 수입
              </Typography>
            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <Typography fontSize={16} fontWeight={700}>
                PASSIVE INCOME을 만드는
              </Typography>
              <Typography fontSize={16} fontWeight={700}>
                반토 파트너스
              </Typography>
            </Box>
            <Box sx={{ marginTop: "15px", textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  height: "50px",
                  width: "150px",
                  backgroundColor: "#3563E9",
                }}
                onClick={() => history.push("/login")}
              >
                시작하기
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <Box sx={{ padding: "10px 0px" }}>
            <Typography>
              {moment().subtract(1, "months").format("M")}월 수익 근황
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Stack spacing={5}>
              {profit &&
                profit.map((item, index) => {
                  if (index < 3) {
                    return (
                      <Box key={index}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <img src={CrownIcon} alt="" />
                            <Typography>
                              {item.user.name}
                              {/* {nameMasking(item.user.name)} */}
                            </Typography>
                          </Stack>
                          <Box>
                            <NumberFormat
                              value={item.totalPrice}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={"원"}
                              style={{ color: "#0021F5" }}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    );
                  }
                })}
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <Box
            sx={{
              backgroundColor: "#3563E9",
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Box>
              <Typography color="white" fontSize={12} fontWeight={400}>
                파트너스 문서
              </Typography>
            </Box>
            <Box sx={{ marginTop: "15px" }}>
              <Typography color="white" fontSize={16} fontWeight={700}>
                수익률에 대해 자세히 알아보세요
              </Typography>
              <Typography color="white" fontSize={16} fontWeight={700}>
                돈을 쓰지않고 지속적인 수입을 만들 수 있어요
              </Typography>
            </Box>
            <Box>
              <img src={MoneyIcon} alt="" />
            </Box>
            <Box sx={{ marginTop: "15px", textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{
                  height: "50px",
                  width: "150px",
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                알아보기
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "10px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ padding: "10px 0px" }}
          >
            <Typography>현재 대여 기록</Typography>
            <IconButton
              onClick={() => {
                getRents();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Stack>
          <Box
            sx={{
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Stack spacing={3}>
              {rents &&
                rents.map((item, index) => {
                  if (index < rentsLimit) {
                    return (
                      <Box key={index}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <CheckIcon
                              color={
                                item.status === "return" ? "primary" : "success"
                              }
                            />
                            <Stack>
                              <Typography>
                                {/* {nameMasking(item.storeName)} */}
                                {item.storeName}
                              </Typography>
                              <Typography sx={{ color: "#BDBDBD" }}>
                                {Math.floor(
                                  moment
                                    .duration(
                                      moment(new Date()).diff(
                                        moment(item.rentalTimeStamp)
                                      )
                                    )
                                    .asMinutes()
                                ) < 60
                                  ? `${Math.floor(
                                      moment
                                        .duration(
                                          moment(new Date()).diff(
                                            moment(item.rentalTimeStamp)
                                          )
                                        )
                                        .asMinutes()
                                    )}분 전`
                                  : `${Math.floor(
                                      moment
                                        .duration(
                                          moment(new Date()).diff(
                                            moment(item.rentalTimeStamp)
                                          )
                                        )
                                        .asHours()
                                    )}시간 전`}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Box>
                            <NumberFormat
                              value={item.price + item.extra_price}
                              displayType={"text"}
                              thousandSeparator={true}
                              suffix={"원"}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    );
                  }
                })}
            </Stack>
            <Box sx={{ textAlign: "center", padding: "20px" }}>
              <Button
                sx={{
                  height: "30px",
                  width: "100px",
                  backgroundColor: "#3563E9",
                }}
                variant="contained"
                onClick={() => {
                  setRentsLimit(rentsLimit + 5);
                }}
              >
                더보기
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Box
            sx={{
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              onClick={() => history.push("/login")}
            >
              <Box>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <img src={CoinIcon} alt="" />
                  <Stack>
                    <Typography>첫 수익 만들기</Typography>
                    <Typography sx={{ color: "#BDBDBD" }}>
                      지금 시작하세요
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <KeyboardArrowRightIcon />
              </Box>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Box
            sx={{
              padding: "15px 20px",
              boxShadow: "0px 2px 4px 5px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() =>
                window.open("https://pf.kakao.com/_eWuNT/chat", "_blank")
              }
            >
              <Box>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <img src={KakaoIcon} alt="" />
                  <Stack>
                    <Typography>문의하기</Typography>
                    <Typography sx={{ color: "#BDBDBD" }}>
                      궁금한게 있으신가요?
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <KeyboardArrowRightIcon />
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Stack spacing={2}>
            <Typography fontSize={20} fontWeight={600}>
              반토 주식회사
            </Typography>
            <Typography color="#90A3BF">
              주소: 서울특별시 서초구 서초동13632 B-109호
            </Typography>
            <Typography color="#90A3BF" fontSize={16}>
              전화: 070-7010-7200
            </Typography>
            <Typography color="#90A3BF" fontSize={16}>
              Email: jaylee@banto.io
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Typography fontSize={12} fontWeight={600}>
                ©2022 BANTO All rights reserved
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Index;
