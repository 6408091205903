import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD-7jpeDG9ubMB0DsJ_T8Xpm9nwbrpFK2g",
  authDomain: "banto-6ebd2.firebaseapp.com",
  projectId: "banto-6ebd2",
  storageBucket: "banto-6ebd2.appspot.com",
  messagingSenderId: "468718592404",
  appId: "1:468718592404:web:b9cf19b3c87aca7bf86f81",
  measurementId: "G-QVH6WFK9ZZ",
};

const app = initializeApp(firebaseConfig);

export default app;
