import React, { useState } from "react";
import moment from "moment";

import DataTable from "components/DataTable";
import Page from "components/Page";

import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";

const BatteryStatus = (params) => {
  const battery = params.value;

  if (battery) {
    return battery.subDeviceSn ? (
      <Tooltip title={`${battery.power}%`}>
        <IconButton>
          <BatteryChargingFullIcon color="success" />
          <Typography fontSize={15}>{battery.subDeviceSn}</Typography>
        </IconButton>
      </Tooltip>
    ) : (
      `빈 슬롯`
    );
  }

  return null;
};

const stationStatus = (params) => {
  return params.value ? (
    <PowerIcon color="success" />
  ) : (
    <PowerOffIcon color="error" />
  );
};

function Index({ info }) {
  const [rows, setRows] = useState(info ?? []);

  const itemCellStyle = {
    height: "100%",
    display: "flex ",
    alignItems: "center",
    whiteSpace: "pre",
  };

  const cellStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center",
  };

  const columns = [
    {
      field: "updatedAt._seconds",
      headerName: "업데이트 시간",
      autoHeight: true,
      width: 150,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value * 1000).format("YYYY-MM-DD")}\n${moment(
          params.value
        ).format("A h:mm:ss")}`;
      },
    },
    { field: "deviceSn", headerName: "스테이션 ID", cellStyle, width: 170 },
    {
      field: "item0",
      headerName: "슬롯 1",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "item1",
      headerName: "슬롯 2",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "item2",
      headerName: "슬롯 3",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "item3",
      headerName: "슬롯 4",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "item4",
      headerName: "슬롯 5",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "item5",
      headerName: "슬롯 6",
      width: 220,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "workState",
      headerName: "WORK STATE",
      width: 100,
      filter: false,
      cellStyle: {
        height: "100%",
        display: "flex ",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "pre",
      },
      cellRenderer: stationStatus,
    },
    {
      field: "state",
      headerName: "STATE",
      width: 100,
      filter: false,
      cellStyle: {
        height: "100%",
        display: "flex ",
        justifyContent: "center",
        alignItems: "center",
        whiteSpace: "pre",
      },
      cellRenderer: stationStatus,
    },
  ];

  return (
    <Page title="스테이션">
      <Container>
        <DataTable columns={columns} rows={rows} pageSize={5} />
      </Container>
    </Page>
  );
}

export default Index;
