import React, { useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";

function WithdrawalForm(props) {
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber ?? "");
  const [rentId, setRentId] = useState(props.rentId ?? "");
  const [userId, setUserId] = useState(props.userId ?? "");
  const [kakaoSid, setKakaoSid] = useState(props.kakaoSid ?? "");
  const [iamportCuid, setIamportCuid] = useState(props.cuid ?? "");
  const [itemName, setItemName] = useState(props.itemName ?? "");
  const [price, setPrice] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async () => {
    try {
      setButtonDisabled(true);

      const requestData = {
        rentId,
        userId,
        itemName,
        price,
      };

      if (kakaoSid) {
        requestData.sid = kakaoSid;
      } else if (iamportCuid) {
        requestData.cuid = iamportCuid;
      }

      const { data: result } = await axios.post(
        "https://api.banto.io/banto2/app/payment/withdrawal",
        requestData
      );
      console.log(requestData);

      setButtonDisabled(false);

      if (result.code == 200) {
        enqueueSnackbar(result.msg, { variant: "success" });
      } else {
        enqueueSnackbar(result.msg, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setRentId("");
    setUserId("");
    setKakaoSid("");
    setIamportCuid("");
    setPrice("");
    setItemName("");
  };

  const handleRentIdChange = (e) => {
    setRentId(e.target.value);
  };

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const handleSidChange = (e) => {
    setKakaoSid(e.target.value);
  };

  const handleCuidChange = (e) => {
    setIamportCuid(e.target.value);
  };

  const handleItemNameChange = (e) => {
    setItemName(e.target.value);
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Stack>
          <FormControl fullWidth>
            <FormLabel id="demo-row-radio-buttons-group-label">출금</FormLabel>
            <TextField
              id="outlined-basic"
              label="휴대폰 번호"
              variant="outlined"
              margin="normal"
              value={phoneNumber}
              disabled
            />
            <TextField
              id="outlined-basic"
              label="렌트 아이디"
              variant="outlined"
              margin="normal"
              value={rentId}
              onChange={handleRentIdChange}
            />
            <TextField
              id="outlined-basic"
              label="유저 아이디"
              variant="outlined"
              margin="normal"
              value={userId}
              onChange={handleUserIdChange}
            />
            <TextField
              id="outlined-basic"
              label="카카오페이 Sid"
              variant="outlined"
              margin="normal"
              value={kakaoSid}
              onChange={handleSidChange}
            />

            <TextField
              id="outlined-basic"
              label="아임포트 cuid"
              variant="outlined"
              margin="normal"
              value={iamportCuid}
              onChange={handleCuidChange}
            />

            <TextField
              id="outlined-basic"
              label="카카오페이 상품명"
              variant="outlined"
              margin="normal"
              value={itemName}
              onChange={handleItemNameChange}
            />
            <TextField
              id="outlined-basic"
              label="가격"
              variant="outlined"
              margin="normal"
              value={price}
              onChange={handlePriceChange}
            />
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button variant="outlined" color="error" onClick={handleReset}>
            리셋
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            onClick={handleSubmit}
          >
            돈 내놔
          </Button>
        </Stack>
      </Container>
    </div>
  );
}

export default WithdrawalForm;
