import React from "react";
import Page from "components/Page";
import WithdrawalForm from "./WithdrawalForm";
import StatusForm from "./StatusForm";
import { Container } from "@mui/material";

const Withdrawal = () => {
  return (
    <Page title={"출금"}>
      <Container maxWidth="xl">
        <WithdrawalForm />
        <StatusForm />
      </Container>
    </Page>
  );
};

export default Withdrawal;
