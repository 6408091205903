import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  Typography,
  Stack,
  Divider,
  Container,
  Grid,
  Chip,
  TextField,
} from "@mui/material";

import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import kakaopayIcon from "assets/img/kakaopay_icon.png";

import axios from "axios";
import GlobalContext from "context/GlobalContext";
import { useSnackbar } from "notistack";
import WithdrawalForm from "../Payment/Withdrawal/WithdrawalForm";
import StatusForm from "../Payment/Withdrawal/StatusForm";

import { Box } from "@mui/system";
import Page from "components/Page";
import DataTable from "components/DataTable";
import DateSearch from "components/DateSearch";

import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

const cellStyle = {
  height: "100%",
  display: "flex",
  alignItems: "center",
};

export default function Rents() {
  const { enqueueSnackbar } = useSnackbar();
  const { role, user } = useContext(GlobalContext);

  const [rows, setRows] = useState(null);

  const [calculate, setCalculate] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const [sid, setSid] = useState("");
  const [cuid, setCuid] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rentId, setRentId] = useState("");
  const [rentStatus, setRentStatus] = useState("");

  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.setDate(now.getDate() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const handleRefund = async (purchaseId) => {
    const message = "환불 하시겠습니까";
    if (!window.confirm(message)) {
      return;
    }
    const { data } = await axios.post(
      process.env.REACT_APP_HOST + "/admin/refund",
      { id: purchaseId, reason: "관리자 취소" }
    );
    if (data.code !== 200) {
      enqueueSnackbar("환불 실패");
      window.alert("환불 실패");
      return;
    }

    enqueueSnackbar("환불 완료");
    handleSearch();

    return;
  };

  const statusCellRenderer = (params) => {
    let paymentType = <img src={kakaopayIcon} alt="" width="30%" />;
    if (params.data.cuid) {
      paymentType = <CreditCardIcon />;
    }

    let color = "";
    let label = "";

    switch (params.value) {
      case "return":
        color = "success";
        label = "반납";
        break;
      case "rent":
        color = "primary";
        label = "대여";
        break;
      case "return_payment_fail":
        color = "error";
        label = "결제 실패";
        break;
      default:
        color = "warning";
        label = params.value;
    }

    return (
      <>
        <Chip
          icon={paymentType}
          label={label}
          size="small"
          color={color}
          sx={{ width: "150px" }}
        />
      </>
    );
  };

  const settingCellRenderer = (params) => {
    return (
      <Button
        size="small"
        onClick={() => {
          handleSettingClick(params.data);
        }}
      >
        <SettingsIcon />
      </Button>
    );
  };

  const basicCellRenderer = (params) => {
    const row = params.data;

    return params.data.refund_price ? (
      <Typography>환불 ({row.price})</Typography>
    ) : (
      <>
        <Box>
          <Typography>{row.price}</Typography>
          {role === "admin" && (
            <Button
              color="primary"
              size="small"
              onClick={() => {
                handleRefund(row.basePurchaseId);
              }}
            >
              <CurrencyExchangeRoundedIcon />
            </Button>
          )}
        </Box>
      </>
    );
  };

  const extraCellRenderer = (params) => {
    const row = params.data;
    return params.data.extra_refund_price ? (
      <Typography>환불 ({row.extra_refund_price})</Typography>
    ) : (
      row.extra_price && (
        <Box>
          <Typography>{row.extra_price}</Typography>
          {role === "admin" && (
            <Button
              color="primary"
              size="small"
              onClick={() => {
                handleRefund(row.extraPurchaseId);
              }}
            >
              <CurrencyExchangeRoundedIcon />
            </Button>
          )}
        </Box>
      )
    );
  };

  const partnersColumns = [
    {
      field: "status",
      headerName: "상태",
      width: 150,
      cellStyle,
      cellRenderer: statusCellRenderer,
    },
    {
      field: "rentalTimeStamp",
      headerName: "대여 시간",
      width: 120,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
          params.value
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "storeName",
      headerName: "대여 장소 / 대여 스테이션",
      width: 190,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${params.value ?? `등록 안됨`}\n${
          params.data.rentalStationId
        } [${params.data.rentalSlotNum}]`;
      },
    },
    {
      field: "returnTimeStamp",
      headerName: "반납시간",
      width: 120,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return (
          params.value &&
          `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
            params.value
          ).format("A h:mm:ss")}`
        );
      },
    },
    {
      field: "return_storeName",
      headerName: "반납 장소 / 반납 스테이션",
      width: 190,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return params.data.status === "return"
          ? `${params.value ?? `등록 안됨`}\n${
              params.data.returnStationId
                ? `${params.data.returnStationId} [${params.data.returnSlotNum}]`
                : `등록 안됨`
            }`
          : "";
      },
    },
    {
      field: "powerBankId",
      headerName: "대여 ID / 대여 배터리",
      width: 180,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${params.data.rentId}\n${params.value}`;
      },
    },
    {
      field: "",
      filter: false,
      headerName: "기본 요금",
      width: 120,
      cellStyle,
      cellRenderer: basicCellRenderer,
    },
    {
      field: "",
      filter: false,
      headerName: "추가 요금",
      width: 120,
      cellStyle,
      cellRenderer: extraCellRenderer,
    },
  ];

  const adminColumns = [
    {
      field: "status",
      headerName: "상태",
      width: 150,
      cellStyle,
      cellRenderer: statusCellRenderer,
    },
    {
      field: "rentalTimeStamp",
      headerName: "대여 시간",
      width: 120,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
          params.value
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "storeName",
      headerName: "대여 장소 / 대여 스테이션",
      width: 190,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${params.value ?? `등록 안됨`}\n${
          params.data.rentalStationId
        } [${params.data.rentalSlotNum}]`;
      },
    },
    {
      field: "returnTimeStamp",
      headerName: "반납시간",
      width: 120,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return (
          params.value &&
          `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
            params.value
          ).format("A h:mm:ss")}`
        );
      },
    },
    {
      field: "return_storeName",
      headerName: "반납 장소 / 반납 스테이션",
      width: 190,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return params.data.status === "return"
          ? `${params.value ?? `등록 안됨`}\n${
              params.data.returnStationId
                ? `${params.data.returnStationId} [${params.data.returnSlotNum}]`
                : `등록 안됨`
            }`
          : "";
      },
    },
    {
      field: "powerBankId",
      headerName: "대여 ID / 대여 배터리",
      width: 180,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${params.data.rentId}\n${params.value}`;
      },
    },
    {
      field: "userId",
      headerName: "유저 정보",
      width: 130,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        if (params.data.phoneNumber) {
          const phoneNumber = params.data.phoneNumber.replace("+82", "0");

          return `${params.value}\n${phoneNumber}`;
        }

        return "";
      },
    },
    {
      field: "",
      filter: false,
      width: 100,
      cellStyle,
      cellRenderer: settingCellRenderer,
    },
    {
      field: "",
      filter: false,
      headerName: "기본 요금",
      width: 120,
      cellStyle,
      cellRenderer: basicCellRenderer,
    },
    {
      field: "",
      filter: false,
      headerName: "추가 요금",
      width: 120,
      cellStyle,
      cellRenderer: extraCellRenderer,
    },
  ];

  const handleSearch = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    let url;
    if (role === "admin") {
      url = `${process.env.REACT_APP_API_URL}/banto2/app/rent?startDate=${start}&endDate=${end}`;
    } else {
      const yearMonth = moment(endDate).format("YYYY-MM");

      url = `${process.env.REACT_APP_API_URL}/banto2/app/partners/rent/${role}/${user}/${yearMonth}`;
      // url = `${process.env.REACT_APP_API_URL}/banto2/app/partners/rent/sales/bfbe6b89bc/${yearMonth}`;
    }
    const { data: rent } = await axios.get(url);

    setRows(rent.data);

    let basicPriceTotal = 0;
    let extraPriceTotal = 0;
    if (rent.data) {
      rent.data.forEach((row) => {
        if (row.price && !row.refund_price) {
          basicPriceTotal += row.price;
        }

        if (row.extra_price && !row.extra_refund_price) {
          extraPriceTotal += row.extra_price;
        }
      });
    }

    console.log(
      basicPriceTotal,
      extraPriceTotal,
      basicPriceTotal + extraPriceTotal
    );

    setCalculate(basicPriceTotal + extraPriceTotal);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSettingClick = (data) => {
    setPhoneNumber(data.phoneNumber);
    setUserId(data.userId);
    setSid(data.kakaoSid);
    setCuid(data.cuid);
    setRentId(data.rentId);
    setRentStatus(data.status);
    setModalOpen(true);
  };

  return (
    <Page title={"대여"}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pb={2}
        >
          <Stack direction="row" spacing={5}>
            <TextField
              label="총 금액"
              value={calculate}
              inputProps={{ readOnly: true }}
            />
            {role === "admin" ? (
              <DateSearch
                startDate={startDate}
                endDate={endDate}
                changeStartDate={(date) => {
                  setStartDate(date);
                }}
                changeEndDate={(date) => {
                  setEndDate(date);
                }}
                searchClick={handleSearch}
              />
            ) : (
              <Stack direction="row" spacing={1}>
                <DatePicker
                  dateFormat="yyyy.MM"
                  selected={endDate}
                  showPopperArrow={false}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  locale={ko}
                  customInput={<TextField label="대여 월" />}
                  showMonthYearPicker
                />
                <Button variant="contained" onClick={handleSearch}>
                  조회
                </Button>
              </Stack>
            )}
          </Stack>
        </Grid>

        <DataTable
          columns={role === "admin" ? adminColumns : partnersColumns}
          rows={rows}
          pageSize={100}
        />
      </Container>

      <>
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <WithdrawalForm
                rentId={rentId}
                userId={userId}
                kakaoSid={sid}
                cuid={cuid}
                phoneNumber={phoneNumber}
              />
              <StatusForm rentId={rentId} status={rentStatus} />
            </Stack>
          </Box>
        </Modal>
      </>
    </Page>
  );
}
