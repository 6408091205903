import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PaidIcon from "@mui/icons-material/Paid";
import StoreIcon from "@mui/icons-material/Store";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useHistory } from "react-router-dom";
import GlobalContext from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import { Logout } from "Auth";

const NavigationDrawer = () => {
  const { t } = useTranslation();
  const {
    drawerIsOpen,
    setDrawerIsOpen,
    setIsLogin,
    isLogin,
    setUser,
    setRole,
    role,
  } = React.useContext(GlobalContext);
  const smView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const history = useHistory();
  const logout = () => {
    sessionStorage.removeItem("token");
    setIsLogin(false);
    setDrawerIsOpen(false);
    setUser(null);
    setRole(null);
    Logout();
  };

  const admin = {
    rent: [
      { text: "대여기록", path: "/rent", icon: <ListAltIcon /> },
      { text: "미반납", path: "/rent/overdue" },
    ],
    partners: [
      { text: "관리", path: "/partners", icon: <PersonOutlineIcon /> },
      { text: "매출", path: "/partners/revenue", icon: <PersonOutlineIcon /> },
      {
        text: "가맹점 추가요청",
        path: "/partners/store/pre",
        icon: <PersonOutlineIcon />,
      },
    ],
    payment: [
      { text: "출금", path: "/payment/withdrawal", icon: <PaidIcon /> },
      { text: "실패", path: "/payment/fail", icon: <PaidIcon /> },
    ],
    user: [{ text: "관리", path: "/user", icon: <PersonOutlineIcon /> }],
    store: [{ text: "관리", path: "/store", icon: <StoreIcon /> }],
    station: [{ text: "관리", path: "/station", icon: <StoreIcon /> }],
  };

  const partners = {
    mypage: [{ text: "내정보", path: "/mypage", icon: <StoreIcon /> }],
    rent: [{ text: "대여 기록", path: "/rent", icon: <ListAltIcon /> }],
    store: [{ text: "가맹점 관리", path: "/store", icon: <StoreIcon /> }],
    station: [{ text: "스테이션 관리", path: "/station", icon: <StoreIcon /> }],
  };

  return (
    <Drawer
      anchor={smView ? "top" : "left"}
      sx={{
        flexShrink: 0,
        width: smView ? "100%" : 240,
        [`& .MuiDrawer-paper`]: {
          width: smView ? "100%" : 240,
          boxSizing: "border-box",
        },
      }}
      open={drawerIsOpen}
      onClose={() => setDrawerIsOpen(!drawerIsOpen)}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        {role === "admin" ? (
          <>
            <Divider>
              <Chip label="대여" />
            </Divider>
            <List>
              {admin.rent.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider>
              <Chip label="결제" />
            </Divider>

            <List>
              {admin.payment.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider>
              <Chip label="회원" />
            </Divider>

            <List>
              {admin.user.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider>
              <Chip label="파트너스" />
            </Divider>

            <List>
              {admin.partners.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider>
              <Chip label="가맹점" />
            </Divider>

            <List>
              {admin.store.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider>
              <Chip label="스테이션" />
            </Divider>

            <List>
              {admin.station.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <List>
              {partners.mypage.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider />

            <List>
              {partners.rent.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider />

            <List>
              {partners.store.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>

            <Divider />

            <List>
              {partners.station.map((items, index) => (
                <ListItem
                  button
                  key={items.text + index}
                  onClick={() => history.push(items.path)}
                >
                  <ListItemIcon>{items.icon ?? ""}</ListItemIcon>
                  <ListItemText primary={items.text} />
                </ListItem>
              ))}
            </List>
          </>
        )}

        <Divider />

        <List>
          {isLogin && (
            <ListItem onClick={logout} button>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t("로그아웃")} />
            </ListItem>
          )}
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
