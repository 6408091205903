import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import DataTable from "components/DataTable";
import Page from "components/Page";

import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import ConstructionIcon from "@mui/icons-material/Construction";
import axios from "axios";
import GlobalContext from "context/GlobalContext";
import { useSnackbar } from "notistack";

const BatteryStatus = (params) => {
  const battery = params.value;

  if (battery) {
    return battery.subDeviceSn ? (
      <Tooltip title={`${battery.power}%`}>
        <Typography fontSize={15}>{battery.subDeviceSn}</Typography>
      </Tooltip>
    ) : (
      `빈 슬롯`
    );
  }

  return null;
};

const stationStatus = (params) => {
  return params.value ? (
    <PowerIcon color="success" />
  ) : (
    <PowerOffIcon color="error" />
  );
};

const PopupButton = (params) => {
  const { enqueueSnackbar } = useSnackbar();

  const popup = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/station/popup`,
        { stationId: params.value }
      );

      enqueueSnackbar(data.msg, { variant: "success" });
    } catch (error) {
      enqueueSnackbar("배터리 팝업 실패", { variant: "error" });
    }
  };

  return (
    <IconButton
      onClick={() => {
        if (window.confirm("배터리를 뽑습니다")) {
          popup();
        }
      }}
    >
      <ConstructionIcon color="primary" />
    </IconButton>
  );
};

function Index({ info }) {
  const [rows, setRows] = useState(info ?? []);
  const { role, user } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      let url;

      if (role === "admin") {
        url = `${process.env.REACT_APP_API_URL}/station`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/station/${role}/${user}`;
      }
      const { data: stations } = await axios.get(url);

      setRows(stations.data);
    })();
  }, []);

  const itemCellStyle = {
    height: "100%",
    display: "flex ",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "pre",
  };

  const columns = [
    {
      field: "firebase.state",
      headerName: "전원",
      width: 80,
      filter: false,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "pre",
      },
      cellRenderer: stationStatus,
    },
    {
      field: "storeName",
      headerName: "가맹점명",
      width: 200,
      cellStyle: {
        height: "100%",
        display: "flex ",
        alignItems: "center",
      },
    },
    {
      field: "stationId",
      headerName: "스테이션 ID",
      width: 200,
      cellStyle: {
        height: "100%",
        display: "flex ",
        alignItems: "center",
      },
    },
    {
      field: "firebase.items.0",
      headerName: "슬롯 1",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "firebase.items.1",
      headerName: "슬롯 2",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "firebase.items.2",
      headerName: "슬롯 3",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "firebase.items.2",
      headerName: "슬롯 4",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "firebase.items.4",
      headerName: "슬롯 5",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    {
      field: "firebase.items.5",
      headerName: "슬롯 6",
      width: 200,
      autoHeight: true,
      filter: false,
      cellStyle: itemCellStyle,
      cellRenderer: BatteryStatus,
    },
    // {
    //   field: "firebase.workState",
    //   headerName: "WORK STATE",
    //   width: 100,
    //   filter: false,
    //   cellStyle: {
    //     height: "100%",
    //     display: "flex ",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     whiteSpace: "pre",
    //   },
    //   cellRenderer: stationStatus,
    // },
    {
      field: "firebase.updatedAt._seconds",
      headerName: "업데이트 시간",
      width: 130,
      filter: false,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value * 1000).format("YYYY-MM-DD")}\n${moment(
          params.value * 1000
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "stationId",
      headerName: "",
      width: 80,
      autoHeight: true,
      filter: false,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "pre",
      },
      cellRenderer: PopupButton,
    },
  ];

  return (
    <Page title="스테이션">
      <Container maxWidth="xl">
        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>
    </Page>
  );
}

export default Index;
