import firebaseApp from "firebaseConfig";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getFirestore } from "@firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth(firebaseApp);
auth.languageCode = "ko";

export const LoginCheck = () => {
  return useAuthState(auth);
};

export const Login = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const Logout = () => {
  return signOut(auth);
};

export const IsAdmin = (email) => {
  const [value, loading, error] = useDocument(
    doc(getFirestore(firebaseApp), "Admins", email),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  console.log(value);
  if (loading) return `이건 로딩 ${loading}`;
  if (error) return `이건 에러 ${error}`;

  console.log(value.data());
  if (value.data()) {
    return true;
  } else {
    return false;
  }
};
