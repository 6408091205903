import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Button,
  TextField,
  Grid,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Divider,
  Box,
  Stack,
  FormGroup,
  FormHelperText,
  Dialog,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import Page from "components/Page";
import Intro from "components/Intro";

import moment from "moment";
import Countdown from "react-countdown";
import DaumPostcode from "react-daum-postcode";

import { Formik, Form } from "formik";
import * as yup from "yup";
import FormInput from "components/FormInput";
import axios from "axios";
import { useLocation, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  countHelperText: {
    "&.MuiFormHelperText-root": {
      textAlign: "right",
      color: "blue",
    },
  },
}));

const banks = [
  { value: "경남은행", label: "경남은행" },
  { value: "광주은행", label: "광주은행" },
  { value: "국민은행", label: "국민은행" },
  { value: "기업은행", label: "기업은행" },
  { value: "농협", label: "농협" },
  { value: "지역농협", label: "지역농협" },
  { value: "대구은행", label: "대구은행" },
  { value: "부산은행", label: "부산은행" },
  { value: "도이치은행", label: "도이치은행" },
  { value: "산업은행", label: "산업은행" },
  { value: "상호저축은행", label: "상호저축은행" },
  { value: "새마을금고", label: "새마을금고" },
  { value: "수협", label: "수협" },
  { value: "신한은행", label: "신한은행" },
  { value: "신협", label: "신협" },
  { value: "씨티은행", label: "씨티은행" },
  { value: "외한은행", label: "외한은행" },
  { value: "우리은행", label: "우리은행" },
  { value: "우체국", label: "우체국" },
  { value: "전북은행", label: "전북은행" },
  { value: "제주은행", label: "제주은행" },
  { value: "카카오뱅크", label: "카카오뱅크" },
  { value: "하나은행", label: "하나은행" },
  { value: "케이뱅크", label: "케이뱅크" },
  { value: "SC은행", label: "SC은행" },
  { value: "HSBC은행", label: "HSBC은행" },
  { value: "교보증권", label: "교보증권" },
  { value: "대신증권", label: "대신증권" },
  { value: "대우증권", label: "대우증권" },
  { value: "동부증권", label: "동부증권" },
  { value: "메리츠종합금융증권", label: "메리츠종합금융증권" },
  { value: "미래에셋증권", label: "미래에셋증권" },
  { value: "부국증권", label: "부국증권" },
  { value: "삼성증권", label: "삼성증권" },
  { value: "신영증권", label: "신영증권" },
  { value: "신한금융투자", label: "신한금융투자" },
  { value: "아이엠투자증권", label: "아이엠투자증권" },
  { value: "우리투자증권", label: "우리투자증권" },
  { value: "유안타증권", label: "유안타증권" },
  { value: "유진투자증권", label: "유진투자증권" },
  { value: "이트레이드증권", label: "이트레이드증권" },
  { value: "키움증권", label: "키움증권" },
  { value: "하나대투증권", label: "하나대투증권" },
  { value: "하이투자증권", label: "하이투자증권" },
  { value: "한국투자증권", label: "한국투자증권" },
  { value: "한화투자증권", label: "한화투자증권" },
  { value: "현대증권", label: "현대증권" },
  { value: "HMC투자증권", label: "HMC투자증권" },
  { value: "LIG투자증권", label: "LIG투자증권" },
  { value: "NH농협증권", label: "NH농협증권" },
  { value: "SK증권", label: "SK증권" },
  { value: "산림조합", label: "산림조합" },
];

const Register = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [emailConfirm, setEmailConfirm] = useState(false);
  const [role, setRole] = useState("sales");
  const [isBusiness, setIsBusiness] = useState(false);
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [recommendCode, setRecommendCode] = useState("");

  const [termChecked, setTermChecked] = useState(false);

  const [authNumber, setAuthNumber] = useState("");
  const [authMessage, setAuthMessage] = useState("인증시간 만료");

  const [countDownDate, setCountDownDate] = useState("");
  const [isAuthTimeOut, setIsAuthTimeOut] = useState(false);
  const [isPhoneAuth, setIsPhoneAuth] = useState(false);
  const [isAuthVerify, setIsAuthVerify] = useState(false);
  const [addressSearchOpen, setAddressSearchOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const recommend = params.get("recommend");

    setRecommendCode(recommend);
  }, []);

  const validate = yup
    .object()
    .shape({
      email: yup
        .string()
        .required("이메일을 입력해주세요")
        .email("이메일 형식이 아닙니다"),
      password: yup
        .string()
        .required("비밀번호를 입력해주세요")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
          "영문/숫자/특수문자 조합 (8~20자)"
        )
        .min(8)
        .max(20),
      passwordConfirm: yup
        .string()
        .required("비밀번호가 일치하지 않습니다")
        .oneOf([yup.ref("password"), null], "비밀번호가 일치하지 않습니다"),
      name: yup
        .string()
        .required("이름을 정확히 입력해주세요")
        .matches(
          /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/,
          "이름을 정확히 입력해주세요"
        ),
      phoneNumber: yup
        .string()
        .required("휴대폰 번호를 정확히 입력해주세요")
        .matches(
          /^[0-9]{3}[0-9]{3,4}[0-9]{4}$/,
          "휴대폰 번호를 정확히 입력해주세요"
        )
        .min(10)
        .max(11),
      businessNumber: yup
        .string()
        .matches(
          /^[0-9]{3}[-]+[0-9]{2}[-]+[0-9]{5}$/,
          "-를 포함한 사업자등록번호를 입력해주세요"
        )
        .min(10),
      term: yup
        .bool()
        .oneOf([true], "You must accept the terms and conditions"),
    })
    .required();

  const handleCheckEmail = async (email) => {
    try {
      const { data: check } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/${email}/check`
      );

      if (check.data) {
        alert("이미 가입된 이메일입니다");
        setEmailConfirm(false);
      } else {
        alert("사용 가능한 이메일입니다");
        setEmailConfirm(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddressSearch = (data) => {
    let fullAddress = data.address;
    let detailAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        detailAddress += data.bname;
      }
      if (data.buildingName !== "") {
        detailAddress +=
          detailAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += detailAddress !== "" ? ` (${detailAddress})` : "";
    }

    setAddress(fullAddress);
    setAddressSearchOpen(false);
  };

  const handlePhoneAuth = async (phoneNumber) => {
    try {
      const { data: sendResult } = await axios.post(
        `https://api.banto.io/banto2/app/auth/phone`,
        { receiver: phoneNumber }
      );

      if (!sendResult.data) {
        alert(sendResult.msg);
        return;
      }

      setCountDownDate(moment() + 180000);

      setIsPhoneAuth(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAuthVerify = async (phoneNumber, authNumber) => {
    try {
      const { data: verifyResult } = await axios.post(
        // `https://api.banto.io/banto2/app/auth/phone/verify`,
        `${process.env.REACT_APP_API_URL}/banto2/app/auth/phone/verify`,
        { receiver: phoneNumber, code: authNumber }
      );

      if (!verifyResult.data) {
        alert("인증번호를 다시 확인해주세요");
        return;
      }

      setIsAuthTimeOut(true);
      setIsAuthVerify(true);
      setAuthMessage("인증되었습니다");
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegister = async ({ email, password, name, phoneNumber }) => {
    try {
      const { data: registerResult } = await axios.post(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners`,
        {
          email,
          password,
          name,
          phoneNumber,
          isBusiness: isBusiness === "true",
          role,
          bank,
          account,
          address,
          detailAddress,
          recommendCode,
        }
      );

      alert(registerResult.msg);

      window.location.replace("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title={t("회원가입")}>
      <Container maxWidth="xs" className={classes.root}>
        <Intro style={{ marginBottom: "20px" }} />

        <Formik
          initialValues={{}}
          validationSchema={validate}
          onSubmit={handleRegister}
        >
          {({ values, errors, touched, getFieldProps }) => (
            <Form>
              <Stack spacing={2}>
                <Box>
                  <FormInput
                    id="email"
                    label="아이디(이메일)"
                    placeholder="이메일"
                    {...getFieldProps("email")}
                  />
                  <Box sx={{ marginTop: "5px", textAlign: "right" }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (touched.email && !errors.email) {
                          handleCheckEmail(values.email);
                        }
                      }}
                    >
                      중복 확인
                    </Button>
                  </Box>
                </Box>

                <FormInput
                  id="password"
                  label="비밀번호"
                  placeholder="영문/숫자/특수문자 조합 (8~20자)"
                  type="password"
                  {...getFieldProps("password")}
                />

                <FormInput
                  id="passwordConfirm"
                  label="비밀번호 확인"
                  type="password"
                  {...getFieldProps("passwordConfirm")}
                />

                <FormInput
                  id="name"
                  label="이름"
                  placeholder="이름"
                  {...getFieldProps("name")}
                />

                <FormInput
                  id="phoneNumber"
                  label="휴대폰 번호"
                  placeholder="-를 제외하고 입력해주세요"
                  inputProps={{ inputMode: "numeric", maxLength: 11 }}
                  {...getFieldProps("phoneNumber")}
                />

                {isPhoneAuth ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      label="인증번호"
                      type="number"
                      disabled={isAuthVerify}
                      value={authNumber}
                      inputProps={{ maxLength: 4 }}
                      onChange={(e) => {
                        setAuthNumber(e.target.value);
                      }}
                      helperText={
                        isAuthTimeOut ? (
                          authMessage
                        ) : (
                          <Countdown
                            date={countDownDate}
                            renderer={({ minutes, seconds, completed }) => {
                              if (completed) {
                                setIsAuthTimeOut(true);
                              } else {
                                return (
                                  <span>
                                    {minutes}:{seconds}
                                  </span>
                                );
                              }
                            }}
                          />
                        )
                      }
                      FormHelperTextProps={{
                        className: classes.countHelperText,
                      }}
                    />

                    <Button
                      variant="outlined"
                      color="natural"
                      disabled={isAuthVerify}
                      onClick={() => {
                        handlePhoneAuth(values.phoneNumber);
                        alert("인증번호가 재전송됐습니다");
                      }}
                    >
                      재전송
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={isAuthVerify}
                      onClick={() => {
                        handleAuthVerify(values.phoneNumber, authNumber);
                      }}
                    >
                      인증
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      disabled={!touched.phoneNumber || !!errors.phoneNumber}
                      onClick={() => {
                        handlePhoneAuth(values.phoneNumber);
                      }}
                    >
                      휴대폰 인증
                    </Button>
                  </Grid>
                )}

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                >
                  <FormLabel>사업자 여부</FormLabel>
                  <FormControl>
                    <RadioGroup
                      row
                      defaultValue={false}
                      onChange={(e) => {
                        setIsBusiness(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="개인"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="사업자"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormHelperText>
                    가입 후
                    {isBusiness === "true"
                      ? " 사업자등록증 업로드가 필요합니다"
                      : " 주민등록번호 입력이 필요합니다"}
                  </FormHelperText>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  p={1}
                >
                  <FormLabel>구분</FormLabel>
                  <FormControl>
                    <RadioGroup
                      row
                      defaultValue="sales"
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="sales"
                        control={<Radio />}
                        label="영업자"
                      />
                      <FormControlLabel
                        value="buyer"
                        control={<Radio />}
                        label="구매자"
                      />
                      <FormControlLabel
                        value="store"
                        control={<Radio />}
                        label="가맹점"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Box sx={{ mt: 5, mb: 5, width: "100%" }}>
                  <Divider>추가 정보</Divider>
                </Box>

                <Stack spacing={1}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={banks}
                    // value={bank}
                    onChange={(e, newValue) => {
                      setBank(newValue.value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="은행" />
                    )}
                  />
                  <TextField
                    fullWidth
                    label="계좌번호"
                    inputProps={{ inputMode: "numeric" }}
                    value={account}
                    onChange={(e) => {
                      setAccount(e.target.value);
                    }}
                    helperText="정산 받으실 계좌를 입력해주세요"
                  />
                </Stack>

                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    label="주소"
                    value={address}
                    onClick={() => {
                      setAddressSearchOpen(true);
                    }}
                    inputProps={{ readOnly: true }}
                  />
                  <TextField
                    fullWidth
                    label="상세 주소"
                    value={detailAddress}
                    onChange={(e) => {
                      setDetailAddress(e.target.value);
                    }}
                    helperText="스테이션을 받으실 주소를 입력해주세요"
                  />

                  {role === "sales" && (
                    <TextField
                      fullWidth
                      label="추천인 코드"
                      value={recommendCode}
                      onChange={(e) => {
                        setRecommendCode(e.target.value);
                      }}
                      // helperText="코드를 입력하시면 포인트가 지급됩니다"
                    />
                  )}
                </Stack>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termChecked}
                        onChange={(e) => {
                          setTermChecked(e.target.checked);
                        }}
                      />
                    }
                    label="[필수] 개인정보 수집 및 이용에 동의합니다"
                  />
                </FormGroup>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    !(
                      Object.keys(values).length > 0 &&
                      Object.keys(touched).length > 4 &&
                      Object.keys(errors).length < 1 &&
                      emailConfirm &&
                      isAuthVerify &&
                      termChecked
                    )
                  }
                >
                  회원가입
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>

        <Dialog
          open={addressSearchOpen}
          onClose={() => {
            setAddressSearchOpen(false);
          }}
        >
          <DaumPostcode onComplete={handleAddressSearch} />
        </Dialog>
      </Container>
    </Page>
  );
};

export default Register;
