import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

import GuestMain from "pages/Guest/Main";
import GuestLogin from "pages/Guest/Login";
import GuestRegister from "pages/Guest/Register";
import GuestForgot from "pages/Guest/Forgot";

import Mypage from "pages/User/Mypage";

import Rent from "pages/User/Rent";
import Withdrawal from "pages/User/Payment/Withdrawal";
import PaymentFail from "pages/User/Payment/Fail";
import GlobalContext from "context/GlobalContext";
import Overdue from "pages/User/Rent/Overdue";
import User from "pages/User/User";

import Partners from "pages/User/Partners";
import PartnersRevenue from "pages/User/Partners/Revenue";
import PreStore from "pages/User/Partners/PreStore";

import Store from "pages/User/Store";
import Station from "pages/User/Station";
import StationDetail from "pages/User/Station/Detail";
import { LoginCheck, Logout } from "Auth";
import axios from "axios";

// path 입력 후 여기로 가서 드로워 수정
import Drawer from "../src/components/Drawer";

const GuestRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={GuestMain} />
      <Route exact path="/login" component={GuestLogin} />
      <Route exact path="/register" component={GuestRegister} />
      <Route exact path="/forgot" component={GuestForgot} />
      <Redirect path="*" to="/" />
    </Switch>
  );
};

const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Rent} />
      <Route exact path="/mypage" component={Mypage} />

      <Route exact path="/rent" component={Rent} />
      <Route exact path="/rent/overdue" component={Overdue} />

      <Route exact path="/payment/withdrawal" component={Withdrawal} />
      <Route exact path="/payment/fail" component={PaymentFail} />

      <Route exact path="/user" component={User} />

      <Route exact path="/partners" component={Partners} />
      <Route exact path="/partners/revenue" component={PartnersRevenue} />
      <Route exact path="/partners/store/pre" component={PreStore} />

      <Route exact path="/store" component={Store} />
      <Route exact path="/station" component={Station} />
      <Route exact path="/station/detail" component={StationDetail} />
    </Switch>
  );
};

const AppRouter = () => {
  const {
    isLogin,
    setIsLogin,
    role,
    setRole,
    user,
    setUser,
    initialize,
    setInitialize,
    setAdminTables,
  } = React.useContext(GlobalContext);

  const [loginUser, loading, error] = LoginCheck();

  const getUser = useCallback(() => {
    if (isLogin) {
      console.log("로딩 : ", loading);
      console.log("유저 : ", loginUser);
      if (error) {
        setIsLogin(false);
        setUser(null);
        setRole(null);
        sessionStorage.removeItem("token");
        Logout();
      }

      if (loading) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <CircularProgress color="primary" />
          </Box>
        );
      }

      if (loginUser) {
        console.log("유저 in Router", loginUser);
        (async () => {
          const { data: userData } = await axios.get(
            `${process.env.REACT_APP_API_URL}/banto2/app/partners/${loginUser.uid}`
          );

          setRole(userData.data.role);
          setUser(loginUser.uid);
          setIsLogin(true);
          sessionStorage.setItem("token", loginUser.accessToken);
          setInitialize(true);
        })();
      }
    } else {
      setInitialize(true);
    }
  }, [
    isLogin,
    setInitialize,
    setIsLogin,
    setUser,
    setRole,
    setAdminTables,
    loading,
  ]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  console.log(initialize, isLogin, user, role);

  return initialize ? (
    isLogin && user ? (
      <UserRoutes />
    ) : (
      <GuestRoutes />
    )
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default AppRouter;
