import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Modal,
  Stack,
  Container,
  Grid,
  TextField,
} from "@mui/material";

import axios from "axios";
import GlobalContext from "context/GlobalContext";

import { Box } from "@mui/system";
import Page from "components/Page";
import DataTable from "components/DataTable";
import DateSearch from "components/DateSearch";

import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { FormControl } from "@mui/material";
import { FormLabel } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";

export default function Rents() {
  const { role, user } = useContext(GlobalContext);

  const [rows, setRows] = useState(null);
  const [searchType, setSearchType] = useState("store");

  const [calculate, setCalculate] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.setDate(now.getDate() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const columns = [
    searchType === "sales" || searchType === "buyer"
      ? { field: "user.name", headerName: "이름", width: 150 }
      : { field: "storeId", headerName: "가맹점 ID", width: 150 },
    searchType === "sales" || searchType === "buyer"
      ? { hide: true }
      : { field: "storeName", headerName: "가맹점", width: 190 },
    searchType === "station"
      ? { field: "stationId", headerName: "스테이션 ID", width: 190 }
      : { hide: true },
    {
      field: "basePrice",
      headerName: "기본 요금",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "extraPrice",
      headerName: "추가 요금",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "refundBasePrice",
      headerName: "기본 요금 환불",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "refundExtraPrice",
      headerName: "추가 요금 환불",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "calcBasePrice",
      headerName: "기본 요금 합산",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "calcExtraPrice",
      headerName: "추가 요금 합산",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
    {
      field: "totalPrice",
      headerName: "총 요금",
      width: 150,
      valueFormatter: (params) => {
        return new Intl.NumberFormat().format(params.value);
      },
    },
  ];

  const handleSearch = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const { data: rent } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/partners/revenue/${searchType}?startDate=${start}&endDate=${end}`
    );

    setRows(rent.data);

    let total = 0;
    if (rent.data) {
      rent.data.forEach((row) => {
        total += row.totalPrice;
      });
    }

    setCalculate(total);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchType]);

  return (
    <Page title={"파트너스 매출"}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pb={2}
        >
          <FormControl>
            <RadioGroup
              row
              value={searchType}
              onChange={(e) => {
                setSearchType(e.target.value);
              }}
            >
              <FormControlLabel
                value="store"
                control={<Radio />}
                label="가맹점"
              />
              <FormControlLabel
                value="station"
                control={<Radio />}
                label="스테이션"
              />
              <FormControlLabel
                value="sales"
                control={<Radio />}
                label="영업자"
              />
              <FormControlLabel
                value="buyer"
                control={<Radio />}
                label="구매자"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pb={2}
        >
          <Stack direction="row" spacing={5}>
            <TextField
              label="총 금액"
              value={calculate}
              inputProps={{ readOnly: true }}
            />
            {role === "admin" ? (
              <DateSearch
                startDate={startDate}
                endDate={endDate}
                changeStartDate={(date) => {
                  setStartDate(date);
                }}
                changeEndDate={(date) => {
                  setEndDate(date);
                }}
                searchClick={handleSearch}
              />
            ) : (
              <Stack direction="row" spacing={1}>
                <DatePicker
                  dateFormat="yyyy.MM"
                  selected={startDate}
                  showPopperArrow={false}
                  maxDate={new Date()}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  locale={ko}
                  customInput={<TextField label="대여 월" />}
                  showMonthYearPicker
                />
                <Button variant="contained" onClick={handleSearch}>
                  조회
                </Button>
              </Stack>
            )}
          </Stack>
        </Grid>

        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>

      <>
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          ></Box>
        </Modal>
      </>
    </Page>
  );
}
