import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";

const Intro = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={5}
      mb={10}
    >
      <IconButton
        onClick={() => history.push("/")}
        sx={{ p: 0 }}
        disableRipple={true}
        disableFocusRipple={true}
        aria-label="menu"
      >
        <Typography variant="h1" component="h1" color="#00C853">
          {t("반토 파트너스")}
        </Typography>
      </IconButton>
    </Box>
  );
};

export default Intro;
