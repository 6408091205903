import React, { useEffect, useState } from "react";
import Page from "components/Page";
import WithdrawalForm from "../Payment/Withdrawal/WithdrawalForm";

import { Button, Chip, Container, Grid, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import PaidIcon from "@mui/icons-material/Paid";
import SettingsIcon from "@mui/icons-material/Settings";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import kakaopayIcon from "assets/img/kakaopay_icon.png";

import axios from "axios";
import moment from "moment";
import StatusForm from "../Payment/Withdrawal/StatusForm";
import DataTable from "components/DataTable";
import DateSearch from "components/DateSearch";

const Overdue = () => {
  const [rows, setRows] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const [sid, setSid] = useState("");
  const [cuid, setCuid] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [rentId, setRentId] = useState("");
  const [rentStatus, setRentStatus] = useState("");

  const [withdrawalOpen, setWithdrawalOpen] = useState(false);

  const now = new Date();
  const [startDate, setStartDate] = useState(
    new Date(now.setMonth(now.getMonth() - 1))
  );
  const [endDate, setEndDate] = useState(new Date());

  const btnCellRenderer = (params) => {
    const { phoneNumber, userId, kakaoSid, cuid, rentId, status } = params.data;

    const handleWithdrawal = () => {
      setPhoneNumber(phoneNumber);
      setUserId(userId);
      setSid(kakaoSid);
      setCuid(cuid);
      setRentId(rentId);
      setRentStatus(status);
      setWithdrawalOpen(true);
      setModalOpen(true);
    };

    const handleReturn = () => {
      setRentId(rentId);
      setRentStatus(status);
      setWithdrawalOpen(false);
      setModalOpen(true);
    };

    return (
      <>
        <Tooltip title="출금">
          <Button
            aria-label="출금"
            onClick={() => {
              handleWithdrawal(params.data);
            }}
          >
            <PaidIcon />
          </Button>
        </Tooltip>
        <Tooltip title="반납 상태 변경">
          <Button
            aria-label="반납 상태"
            onClick={() => {
              handleReturn(params.data);
            }}
          >
            <SettingsIcon />
          </Button>
        </Tooltip>
      </>
    );
  };

  const statusCellRenderer = (params) => {
    let paymentType = <img src={kakaopayIcon} alt="" width="30%" />;
    if (params.data.cuid) {
      paymentType = <CreditCardIcon />;
    }

    let label = "";
    let color = "";

    if (params.value) {
      label = "출금 완료";
      color = "primary";
    } else {
      label = "출금 전";
      color = "error";
    }

    return (
      <>
        <Chip
          icon={paymentType}
          label={label}
          size="small"
          color={color}
          sx={{ width: "130px" }}
        />
      </>
    );
  };

  const columns = [
    {
      field: "rentalTimeStamp",
      headerName: "대여 시간",
      width: 140,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueFormatter: (params) => {
        return `${moment(params.value).format("YYYY-MM-DD")}\n${moment(
          params.value
        ).format("A h:mm:ss")}`;
      },
    },
    {
      field: "storeNameWithStationId",
      headerName: "가맹점 / 스테이션 ID",
      width: 180,
      autoHeight: true,
      cellStyle: { whiteSpace: "pre" },
      valueGetter: (params) => {
        return `${params.data.storeName ?? "등록된 가맹점이 없습니다"}\n${
          params.data.stationId
        }`;
      },
    },
    {
      field: "powerBankId",
      headerName: "배터리 ID",
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "rentId",
      width: 120,
      headerName: "대여 ID",
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "userId",
      width: 120,
      headerName: "유저 ID",
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    {
      field: "phoneNumber",
      width: 130,
      headerName: "휴대폰 번호",
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      valueFormatter: (params) => {
        return params.value.replace("+82", "0");
      },
    },
    {
      field: "extraPurchaseId",
      filter: false,
      width: 130,
      headerName: "출금",
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      cellRenderer: statusCellRenderer,
    },
    {
      field: "",
      filter: false,
      width: 160,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      cellRenderer: btnCellRenderer,
    },
  ];

  const handleSearch = async () => {
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/rent/overdue?startDate=${start}&endDate=${end}`
    );

    setRows(data.list);
  };
  useEffect(() => {
    (async () => {
      handleSearch();
    })();
  }, []);

  return (
    <Page title={"미반납"}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pb={2}
        >
          <DateSearch
            startDate={startDate}
            endDate={endDate}
            changeStartDate={(date) => {
              setStartDate(date);
            }}
            changeEndDate={(date) => {
              setEndDate(date);
            }}
            searchClick={handleSearch}
          />
        </Grid>

        <DataTable
          columns={columns}
          rows={rows}
          pageSize={10}
          noRowsToShow="미반납된 배터리가 없습니다"
        />
      </Container>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            witdh: 400,
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {withdrawalOpen ? (
            <WithdrawalForm
              rentId={rentId}
              userId={userId}
              kakaoSid={sid}
              cuid={cuid}
              itemName="반토 보조배터리 미반납"
              phoneNumber={phoneNumber}
            />
          ) : (
            <StatusForm rentId={rentId} status={rentStatus} />
          )}
        </Box>
      </Modal>
    </Page>
  );
};

export default Overdue;
