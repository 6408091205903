import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Button, Stack, Link } from "@mui/material";

import Page from "components/Page";
import { Formik, Form } from "formik";
import FormInput from "components/FormInput";
import GlobalContext from "context/GlobalContext";
import * as yup from "yup";

import * as Auth from "Auth";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Typography } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Login = ({ history }) => {
  const { setIsLogin, setUser, setRole } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const errorSnackbar = () => {
    enqueueSnackbar("정확한 로그인 정보를 입력해주세요", {
      variant: "error",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const validate = yup.object().shape({
    email: yup
      .string()
      .required("이메일을 입력해주세요")
      .email("이메일 형식이 아닙니다"),
    password: yup
      .string()
      .required("비밀번호를 입력해주세요")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
        "영문/숫자/특수문자 조합 (8~20자)"
      )
      .min(8)
      .max(20),
  });

  const handleLogin = async ({ email, password }) => {
    try {
      const { user: userAuth } = await Auth.Login(email, password);

      const { data: userData } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/${userAuth.uid}`
      );

      setUser(userAuth.uid);
      setRole(userData.data.role);
      setIsLogin(true);
      history.replace("/");
    } catch (error) {
      console.log(error);

      errorSnackbar();
    }
  };

  return (
    <Page title={t("로그인")}>
      <Container sx={{ backgroundColor: "#F6F7F9" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          mb={4}
        >
          <ArrowBackIosNewIcon onClick={() => history.push("/")} />
        </Stack>
        <Box
          sx={{
            padding: "50px 20px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
          }}
        >
          <Box mb={4}>
            <Typography fontSize={24} fontWeight={400}>
              로그인
            </Typography>
            <Typography fontSize={14} fontWeight={400}>
              반토 파트너스
            </Typography>
          </Box>

          <Formik
            initialValues={{}}
            validationSchema={validate}
            onSubmit={handleLogin}
          >
            {({ getFieldProps }) => (
              <Form>
                <Stack spacing={2}>
                  <Box>
                    <FormInput
                      id="email"
                      label="아이디(이메일)"
                      placeholder="이메일"
                      {...getFieldProps("email")}
                    />
                  </Box>

                  <FormInput
                    id="password"
                    label="비밀번호"
                    placeholder="영문/숫자/특수문자 조합 (8~20자)"
                    type="password"
                    {...getFieldProps("password")}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ backgroundColor: "#3563E9" }}
                  >
                    로그인
                  </Button>

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Link href="register" color="GrayText" underline="none">
                        회원가입
                      </Link>
                      {""} <span style={{ color: "gray" }}>|</span> {""}
                      <Link href="forgot" color="GrayText" underline="none">
                        비밀번호 찾기
                      </Link>
                    </Box>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Page>
  );
};

export default Login;
