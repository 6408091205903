// https://www.ag-grid.com/react-data-grid

import React, { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const DataTable = ({ columns, rows, ...props }) => {
  const gridRef = useRef(null);
  const defaultColDef = {
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    resizable: true,
    // filterParams: {
    //   buttons: ["reset", "apply"],
    //   debounceMs: 300,
    // },
    cacheQuickFilter: true,
  };

  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        ref={gridRef}
        style={{ width: "100%", height: "100%" }}
        headerHeight="30"
        columnDefs={columns}
        rowData={rows}
        floatingFiltersHeight="30"
        defaultColDef={defaultColDef}
        pagination={true}
        paginationPageSize={props.pageSize ?? 10}
        localeText={{
          noRowsToShow: props.noRowsToShow ?? "조회된 데이터가 없습니다",
        }}
        skipHeaderOnAutoSize={true}
        rowSelection={props.rowSelection ?? null}
        onRowSelected={props.onRowSelected ?? null}
        domLayout="autoHeight"
        enableCellTextSelection={true}
      />
    </div>
  );
};

export default DataTable;
