import {
  Container,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ImageIcon from "@mui/icons-material/Image";

import axios from "axios";
import DataTable from "components/DataTable";
import Page from "components/Page";
import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

function Index() {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);

  const [searchRole, setSearchRole] = useState("sales");

  const [stationId, setStationId] = useState("");
  const [userId, setUserId] = useState("");
  const [userRole, setUserRole] = useState("sales");
  const [portion, setPortion] = useState(0);

  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

  const registrationButton = (params) => {
    const { id, role } = params.data;

    setUserId(id);
    setUserRole(role);

    return (
      <>
        <Tooltip title="스테이션 등록">
          <Button
            onClick={() => {
              setRegistrationModalOpen(true);
            }}
          >
            <AddBoxIcon />
          </Button>
        </Tooltip>
      </>
    );
  };

  const licenseDownloadButton = (params) => {
    return (
      <Button
        onClick={() => {
          if (params.value) {
            window.open(params.value);
          } else {
            enqueueSnackbar("사업자등록증 이미지가 없습니다.", {
              variant: "warning",
            });
          }
        }}
      >
        <ImageIcon />
      </Button>
    );
  };

  const handleRegistration = async () => {
    try {
      const { data: registResult } = await axios.post(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/station/pre`,
        {
          stationId,
          userId,
          role: userRole,
          portion,
        }
      );

      enqueueSnackbar("스테이션 등록 성공", { variant: "info" });
    } catch (error) {
      enqueueSnackbar("스테이션 등록 실패", { variant: "error" });
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "name",
      headerName: "이름",
      width: 100,
    },
    {
      field: "bank.name",
      headerName: "은행",
      width: 150,
    },
    { field: "bank.account", headerName: "계좌번호", width: 200 },
    {
      field: "email",
      headerName: "이메일",
      width: 200,
    },
    {
      field: "isBusiness",
      headerName: "사업자 여부",
      width: 100,
      valueFormatter: (params) => {
        return params.value ? "사업자" : "개인";
      },
    },
    {
      field: "license.number",
      headerName: "개인정보",
      width: 150,
    },
    {
      field: "license.image",
      headerName: "사업자등록증",
      width: 150,
      cellStyle: {
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      cellRenderer: licenseDownloadButton,
    },
    {
      field: "phoneNumber",
      headerName: "휴대폰 번호",
      width: 150,
    },
    {
      field: "address.default",
      headerName: "주소",
      width: 400,
    },
    {
      field: "address.detail",
      headerName: "상세주소",
      width: 200,
    },
    {
      field: "",
      filter: false,
      width: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      cellRenderer: registrationButton,
    },
  ];

  useEffect(() => {
    (async () => {
      const { data: partners } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/partners/role/${searchRole}`
      );

      setRows(partners.data);
    })();
  }, [searchRole]);

  return (
    <Page title={"파트너스 영업자"}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pb={2}
        >
          <FormControl>
            <FormLabel>구분</FormLabel>
            <RadioGroup
              row
              value={searchRole}
              onChange={(e) => {
                setSearchRole(e.target.value);
              }}
            >
              <FormControlLabel
                value="sales"
                control={<Radio />}
                label="영업자"
              />
              <FormControlLabel
                value="buyer"
                control={<Radio />}
                label="구매자"
              />
              <FormControlLabel
                value="store"
                control={<Radio />}
                label="가맹점"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 800 } }}
        maxWidth="xs"
        open={registrationModalOpen}
      >
        <DialogTitle>스테이션 등록</DialogTitle>
        <DialogContent dividers>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <TextField
              fullWidth
              label="스테이션 번호"
              value={stationId}
              onChange={(e) => {
                setStationId(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="파트너스 아이디"
              value={userId}
              inputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              label="비율"
              helperText="최대 35%"
              value={portion}
              onChange={(e) => {
                setPortion(e.target.value);
              }}
            />
            <FormControl>
              <RadioGroup
                row
                value={userRole}
                onChange={(e) => {
                  setUserRole(e.target.value);
                }}
              >
                <FormControlLabel
                  value="sales"
                  control={<Radio />}
                  label="영업자"
                />
                <FormControlLabel
                  value="buyer"
                  control={<Radio />}
                  label="구매자"
                />
                <FormControlLabel
                  value="store"
                  control={<Radio />}
                  label="가맹점"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="natural"
              onClick={() => {
                setRegistrationModalOpen(false);
                setStationId("");
                setPortion(0);
              }}
            >
              취소
            </Button>
            <Button fullWidth variant="contained" onClick={handleRegistration}>
              등록
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Page>
  );
}

export default Index;
