import { Container } from "@mui/material";
import axios from "axios";
import DataTable from "components/DataTable";
import Page from "components/Page";
import React, { useEffect, useState } from "react";

function Index() {
  const [rows, setRows] = useState([]);

  const columns = [
    { field: "userId", headerName: "유저 ID", width: 300 },
    {
      field: "email",
      headerName: "이메일",
      width: 250,
    },
    { field: "kakaoId", headerName: "카카오 ID", width: 150 },
    {
      field: "cuid",
      headerName: "iamport CUID",
      width: 230,
    },
    {
      field: "kakaoSid",
      headerName: "카카오 SID",
      width: 230,
    },
    {
      field: "phoneNumber",
      headerName: "휴대폰 번호",
      width: 150,
      valueFormatter: (params) => {
        return params.value.replace("+82", "0");
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/banto2/app/user`
      );

      setRows(data.list);
    })();
  }, []);

  return (
    <Page title={"회원"}>
      <Container maxWidth="xl">
        <DataTable columns={columns} rows={rows} pageSize={100} />
      </Container>
    </Page>
  );
}

export default Index;
