import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import DataTable from "components/DataTable";
import Page from "components/Page";
import Station from "../Station/Detail";

import SentimentSatisfiedAltTwoToneIcon from "@mui/icons-material/SentimentSatisfiedAltTwoTone";
import SentimentDissatisfiedTwoToneIcon from "@mui/icons-material/SentimentDissatisfiedTwoTone";
import AdfScannerIcon from "@mui/icons-material/AdfScanner";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Container,
  Tooltip,
  Grid,
  TextField,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";

import { useSnackbar } from "notistack";
import DaumPostcode from "react-daum-postcode";
import GlobalContext from "context/GlobalContext";

const IsStoreOpenRenderer = (params) => {
  return params.value ? (
    <Tooltip title="영업 중">
      <SentimentSatisfiedAltTwoToneIcon color="success" />
    </Tooltip>
  ) : (
    <Tooltip title="영업 종료">
      <SentimentDissatisfiedTwoToneIcon color="error" />
    </Tooltip>
  );
};

const RefreshButton = (params) => {
  const { storeId } = params.data;

  const [btnDisabled, setBtnDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    setBtnDisabled(true);
    await axios.post(
      `${process.env.REACT_APP_API_URL}/station/status/refresh`,
      {
        storeId,
      }
    );
    setBtnDisabled(false);

    enqueueSnackbar("스테이션 상태 새로고침 성공", { variant: "info" });
  };

  return (
    <>
      <Button onClick={handleClick} disabled={btnDisabled}>
        <RefreshIcon />
      </Button>
    </>
  );
};

const StationListButton = (params) => {
  const [stationInfo, setStationInfo] = useState([]);
  const [open, setOpen] = useState(false);

  const { storeId } = params.data;

  const handleClick = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/store/${storeId}/station`
    );

    setStationInfo(data.data);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <AdfScannerIcon />
      </Button>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">스테이션</DialogTitle>
        <DialogContent dividers={true}>
          <Station info={stationInfo} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

function Index() {
  const { role, user } = useContext(GlobalContext);
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);

  const [preStationIdList, setPreStationIdList] = useState([]);

  const [applicationModalOpen, setApplicationModalOpen] = useState(false);

  const [stationIds, setStationIds] = useState([]);
  const [portion, setPortion] = useState(0);
  const [storeName, setStoreName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [weekdaysStart, setWeekdaysStart] = useState("");
  const [weekdaysEnd, setWeekdaysEnd] = useState("");
  const [weekendsStart, setWeekendsStart] = useState("");
  const [weekendsEnd, setWeekendsEnd] = useState("");
  const [closeDays, setCloseDays] = useState("");
  const [addressSearchOpen, setAddressSearchOpen] = useState(false);

  const [sms, setSms] = useState("N");

  const handleAddressSearch = (data) => {
    let fullAddress = data.address;
    let detailAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        detailAddress += data.bname;
      }
      if (data.buildingName !== "") {
        detailAddress +=
          detailAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += detailAddress !== "" ? ` (${detailAddress})` : "";
    }

    setDefaultAddress(fullAddress);
    setAddressSearchOpen(false);
  };

  const handleApplication = async () => {
    try {
      let url;
      if (role === "admin") {
        url = `${process.env.REACT_APP_API_URL}/banto2/app/store`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/banto2/app/partners/store/pre`;
      }

      const { data: applicationResult } = await axios.post(url, {
        userId: user,
        stationIds,
        portion,
        storeName,
        phoneNumber,
        address: `${defaultAddress}${detailAddress && ` ${detailAddress}`}`,
        weekdaysStart,
        weekdaysEnd,
        weekendsStart,
        weekendsEnd,
        closeDays,
        sms: sms === "Y" ? true : false,
      });

      if (applicationResult.data) {
        enqueueSnackbar("추가 완료", { variant: "info" });
        setApplicationModalOpen(false);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("추가 실패", { variant: "error" });
    }
  };

  const columns = [
    { field: "storeId", headerName: "가맹점 ID", width: 100 },
    {
      field: "storeName",
      headerName: "가맹점명",
    },
    { field: "address", headerName: "주소", width: 300 },
    {
      field: "phoneNumber",
      headerName: "전화번호",
      valueFormatter: (params) => {
        return params.value.replace("+82", "0");
      },
    },
    {
      field: "businessStart",
      headerName: "영업 시작시간",
      width: 150,
    },
    {
      field: "businessEnd",
      headerName: "영업 종료시간",
      width: 150,
    },
    { field: "closeDays", headerName: "휴무일", width: 100 },
    {
      field: "isStoreOpen",
      headerName: "",
      filter: false,
      width: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center ",
      },
      cellRenderer: IsStoreOpenRenderer,
    },
    {
      field: "",
      filter: false,
      width: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center ",
      },
      cellRenderer: RefreshButton,
    },

    {
      field: "",
      filter: false,
      width: 100,
      cellStyle: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center ",
      },
      cellRenderer: StationListButton,
    },
  ];

  const getStore = async () => {
    let url;

    if (role === "admin") {
      url = `${process.env.REACT_APP_API_URL}/banto2/app/store`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/banto2/app/store/${role}/${user}`;
    }

    const { data: store } = await axios.get(url);

    setRows(store.data);
  };

  const getPreStation = async () => {
    const { data: preStationIds } = await axios.get(
      `${process.env.REACT_APP_API_URL}/banto2/app/partners/station/pre/${user}`
    );

    const preStationIdsArr = [];
    preStationIds.data.forEach((item) => {
      preStationIdsArr.push(item.stationId);
    });

    setPreStationIdList(preStationIdsArr);
  };

  useEffect(() => {
    getStore();
    getPreStation();
  }, []);

  return (
    <Page title="가맹점">
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          pb={2}
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              setApplicationModalOpen(true);
            }}
          >
            {role === "admin" ? "가맹점 등록" : "가맹점 추가"}
          </Button>
        </Grid>
        <DataTable columns={columns} rows={rows} pageSize={50} />
      </Container>

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 800 } }}
        maxWidth="xs"
        open={applicationModalOpen}
      >
        <DialogTitle>
          {role === "admin" ? "가맹점 등록" : "가맹점 추가"}
        </DialogTitle>
        <DialogContent dividers>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Autocomplete
              multiple
              fullWidth
              options={preStationIdList}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              onChange={(e, value) => {
                setStationIds(value);
                console.log(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="스테이션"
                  placeholder="스테이션"
                />
              )}
            />

            <TextField
              fullWidth
              label="비율"
              value={portion}
              helperText="최대 35%"
              onChange={(e) => {
                setPortion(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="가맹점명"
              value={storeName}
              onChange={(e) => {
                setStoreName(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="전화번호"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="주소"
              value={defaultAddress}
              onChange={(e) => {
                setDefaultAddress(e.target.value);
              }}
              onClick={() => {
                setAddressSearchOpen(true);
              }}
              inputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              label="상세 주소"
              value={detailAddress}
              onChange={(e) => {
                setDetailAddress(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="주중 오픈시간"
              placeholder="12:00:00"
              value={weekdaysStart}
              onChange={(e) => {
                setWeekdaysStart(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="주중 마감시간"
              placeholder="12:00:00"
              value={weekdaysEnd}
              onChange={(e) => {
                setWeekdaysEnd(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="주말 오픈시간"
              placeholder="12:00:00"
              value={weekendsStart}
              onChange={(e) => {
                setWeekendsStart(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="주말 마감시간"
              placeholder="12:00:00"
              value={weekendsEnd}
              onChange={(e) => {
                setWeekendsEnd(e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="휴무일"
              placeholder="월, 화"
              value={closeDays}
              onChange={(e) => {
                setCloseDays(e.target.value);
              }}
            />
            <FormControl>
              <FormLabel>초대 문자 전송여부</FormLabel>
              <RadioGroup
                row
                value={sms}
                onChange={(e) => {
                  setSms(e.target.value);
                }}
              >
                <FormControlLabel value="Y" control={<Radio />} label="전송" />
                <FormControlLabel
                  value="N"
                  control={<Radio />}
                  label="전송 안함"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              color="natural"
              onClick={() => {
                setApplicationModalOpen(false);
              }}
            >
              취소
            </Button>
            <Button fullWidth variant="contained" onClick={handleApplication}>
              추가
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={addressSearchOpen}
        onClose={() => {
          setAddressSearchOpen(false);
        }}
      >
        <DaumPostcode onComplete={handleAddressSearch} />
      </Dialog>
    </Page>
  );
}

export default Index;
